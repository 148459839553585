<template>
    <a-drawer
      :title="title"
      :visible="visible"
      :placement="'top'"
      @close="$emit('close')"
      :height="'100%'"
      :wrapClassName="'addClasses'"
    >
        <div class="content">
           <a-form
                :form="form"
                v-bind="formItemLayout"
            >
                <a-form-item label="科目名称">
                   <a-input
                           v-decorator="['name', { initialValue: formData.name, rules: [{ required: true, message: '请输入科目名称' }] }]"
                   />
                </a-form-item>
                <a-form-item label="上传封面图">
                    <a-upload
                        name="avatar"
                        list-type="picture-card"
                        :show-upload-list="false"
                        :customRequest="customRequest"
                        v-decorator="['fileImg',
                            { rules: [{ required: true, message: '请上传图片' }] },
                        ]"
                    >
                        <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                        <div v-else>
                            <a-icon :type="loading ? 'loading' : 'plus'" style="font-size:30px;color:#dcdcdc"/>
                            <div>上传图片</div>
                        </div>
                    </a-upload>
                    <span>建议大小不超过10M,格式为jpg</span>
                </a-form-item>
                <a-form-item label="考试大纲" v-bind="formItemLayout" style="position: relative;z-index: 1;">
                    <Editor :value="formData.examDesc" @input="handleInput" placeholder="请输入考试大纲" style="min-height: 200px"
                            v-decorator="['examDesc', { initialValue: formData.examDesc, rules: [{ required: true, message: '请输入考试大纲' }] }]"
                    ></Editor>
                </a-form-item>
           </a-form>
            <div style="text-align:right;padding-top:30px;padding-right:20%">
                <a-button class="mr20px" style="width:100px" @click="$emit('close')">取消</a-button>
                <a-button type="primary"  style="width:100px" @click="submit">确认编辑</a-button>
            </div>
      </div>
    </a-drawer>
</template>
<script>
import { uploadImg } from '@/libs/api'
import * as http from '@/libs/practice'
import Editor from '@/components/wangeditor'
export default {
    name: 'addClasses',
    components:{ Editor },
    props: ['visible', 'options', 'data', 'edit', 'allSubject'],
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    data(){
        return{
            formItemLayout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 10 },
            },
            imageUrl: '',
            user_type: 1,
            detail_type: 1,
            showP:false,
            loading: false,
            subjectList: [],
            ProfessList:[],
            detailUrl:'',
            title:'编辑科目',
            showSchool: false,
            showBpid: false,
            SchoolList: [],
            BpidList: [],
            detailfileImg:[],
            detail_bg_url:'',
            formData:{
                name:'',
                examDesc:''
            }
        }
    },
    watch:{
        visible(val){
            if(!val){
                this.reset()
            }else{
                this.init().then(()=>{
                    if(this.edit){
                        Object.assign(this.formData, this.data);
                        this.title = '编辑科目'
                        this.imageUrl = this.data.img
                        this.formData.name = this.data.cname
                        this.formData.examDesc = this.data.examDesc
                        setTimeout(()=>{
                            this.form.setFieldsValue({'fileImg':[{uid:-1, name: 'image.png',status: 'done', url: this.data.img}]})
                        })
                    }else{
                        this.title = '新增科目'
                    }
                })
            }
        }
    },
    methods:{
        // 富文本编辑器内容变更
        handleInput (html) {
            this.formData.examDesc = html
        },
        reset(){
            this.form.resetFields()
            this.imageUrl = ''
            this.loading = false
            this.formData = {
                name:'',
                examDesc:''
            }
        },
        init(){
            return new Promise((resolve,reject) => {
                Promise.all([]).then(res=>{
                    resolve()
                }).catch(reject)
            })
        },
        customRequest(data){
            let formData = new FormData()
            formData.append('file',data.file)
            uploadImg(formData).then(res=>{
                if(res){
                    this.imageUrl=res.fileUrl
                }
            },error=>{
             console.log(error)
            })
        },
        submit(){
            this.form.validateFields((err, values) => {
                if(err) return;
                let request = this._.cloneDeep(values)
                delete request.fileImg
                request.img = this.imageUrl
                request.examDesc = this.formData.examDesc
                request.id = this.data.id
                if(this.edit){
                    http.edit_exam_bag(request).then(res=>{
                        this.$emit('close')
                        this.$emit('update')
                    }).catch(error=>{
                        console.log(error)
                    })
                }else{
                    http.add_exam_bag(request).then(res=>{
                        this.$emit('close')
                        this.$emit('update')
                    }).catch(error=>{
                        console.log(error)
                    })
                }
            })
        },
        vaildatorPrice(rule, value, callback){
        }
    }
}
</script>
<style lang="less" scoped>
.sTable{
    width: 420px;
    border: 1px solid #eee;
    .th{
        background: #eeeeee;
        height: 32px;
        display: flex;
        align-items: center;
        text-align: center;
        >div{
            flex: 1;
        }
    }
    .tbody{
        padding: 20px 10px;
        display: flex;
        align-items: center;
        >div{
            flex: 1;
        }
    }
}
</style>
