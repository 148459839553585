<template>
    <div class="task">
        <Loading v-if="loading"></Loading>
        <AddClasses :visible="openClasses" @close="openClasses = false" :data="currentItem" :edit="editClass" @update="getList"></AddClasses>
        <!-- 添加二级分类 -->
        <a-modal
            title="新增考试"
            :visible="parentClass.visible"
            @ok="handleOk"
            :confirmLoading="parentClass.confirmLoading"
            @cancel="parentClass.visible = false"
            v-if="parentClass.visible"
            centered
        >
            <a-form :form="form">
                <a-form-item label="新增考试" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                    <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入新增考试名!' }] }]" />
                </a-form-item>
            </a-form>
        </a-modal>
        <!--<a-modal
                title="新增科目"
                :visible="parentClass.visible1"
                @ok="handleOk"
                :confirmLoading="parentClass.confirmLoading1"
                @cancel="parentClass.visible1 = false"
                v-if="parentClass.visible1"
                centered
        >
            <a-form :form="form">
                <a-form-item label="新增科目" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                    <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入新增科目名!' }] }]" />
                </a-form-item>
            </a-form>
        </a-modal>-->
        <div class="clearfix">
            <div class="fR">
                <a-button type="dashed" @click="AddClass(null, 1)">新增考试</a-button>
            </div>
        </div>

        <a-table :columns="columns" rowKey="id" :dataSource="list" style="margin-top:20px" @expand="expand" :expandedRowKeys="expandedRowKeys" :childrenColumnName="'son'" class="table">
            <template slot="option" slot-scope="text,record">
                <a-button type="primary" style="margin-right:10px" @click="AddClass1(record, 2)" v-if="record.level==0">添加科目</a-button>
                <a-button type="primary" style="margin-right:10px" @click="openClasses = true; currentItem = record; editClass = true" v-if="record.level==1">修改</a-button>
                <a-button type="primary" ghost style="margin-right:10px" @click="$router.push({path:'/practice/unit', query: {id: 1, exam: record.id}})" v-if="record.level==1">单元练习</a-button>
                <a-button type="primary" ghost style="margin-right:10px" @click="$router.push({path:'/practice/unit', query: {id: 2, exam: record.id}})" v-if="record.level==1">考点练习</a-button>
                <a-button type="primary" ghost style="margin-right:10px" @click="$router.push({path:'/practice/examination', query: {type: 1, exam: record.id}})" v-if="record.level==1">历年真题</a-button>
                <a-button type="primary" ghost style="margin-right:10px" @click="$router.push({path:'/practice/examination', query: {type: 2, exam: record.id}})" v-if="record.level==1">模拟考试</a-button>
                <a-button type="primary" ghost style="margin-right:10px" @click="$router.push({path:'/practice/examination', query: {type: 3, exam: record.id}})" v-if="record.level==1">补充题库</a-button>

                <a-popconfirm
                    title="确定删除吗?"
                    @confirm="deletClass(record.id, record.parent_id)"
                    okText="确定"
                    cancelText="取消"
                >
                    <a-button type="danger">删除</a-button>
                </a-popconfirm>
            </template>
            <template slot="index" slot-scope="text, record">
                <span class="num">{{text}}</span><span>{{record.cname}}</span>
            </template>
        </a-table>
        <a-modal title="修改" centered v-model="visible" @ok="editCate">
            <template>
                <a-input v-model="editType" style="margin: 30px  0"/>
            </template>
        </a-modal>
    </div>
</template>
<script>
const columns = [
    { title: '序号/名称', dataIndex: 'index', scopedSlots: { customRender: 'index' }, },
    // { title: '类别', dataIndex: 'cname', width: 200, align: 'center' },
    { title: '操作', dataIndex: 'option', scopedSlots: { customRender: 'option' },  align: 'center' },
]
import Loading from '@/components/loading'
import { get_exam_list, add_exam_bag, del_exam_bag, edit_exam_bag } from '@/libs/practice'
import AddClasses from '@/components/practice/addClasses'
// import { IconFont } from '@/libs/IconFont'
export default {
    name:'setting',
    components:{ Loading,AddClasses },
    data(){
        return{
            form: this.$form.createForm(this),
            columns,
            currentId:'',
            classType:1,
            parentClass:{
                visible:false,
                confirmLoading:false,
                visible1:false,
                confirmLoading1:false,
            },
            id:1,
            loading:false,
            total:'',
            list:[],
            flatList:[],
            pagination:{
                page:1,
                size:10
            },
            expandedRowKeys: [],
            visible: false,
            editType: '',
            editId: '',
            currentparent: null,
            //科目详情
            openClasses: false,
            currentItem:'',
            editClass:false
        }
    },
    filters:{
        num(val){
            if (val<9){
                return `0${val+1}`
            }else{
                return val
            }
        }
    },
    mounted(){
        this.getList()
    },
    methods:{
        expand(expanded, record){
            if(!expanded){
                let index = this.expandedRowKeys.findIndex(el=>el == record.id)
                this.expandedRowKeys.splice(index, 1)
            }else{
                this.expandedRowKeys.push(record.id)
            }
        },
        handleOk(e){
            e.preventDefault();
            this.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    this.parentClass.confirmLoading = true
                    let request = {}
                    if(this.classType == 1){
                        request = { ...values }
                    }else if(this.classType == 2){
                        request = { id:this.currentId, ...values }
                    }
                    add_exam_bag(request).then(res=>{
                        this.findItemById(this.list, request, res.id)
                        this.flatList.push(res.id)
                        this.parentClass.visible = false
                        this.parentClass.confirmLoading = false
                        this.getList();
                    },error=>{
                        console.log(error)
                        this.parentClass.confirmLoading = false
                    })
                }
            });
        },
        findItemById(datas, {id}, data){
            datas.forEach(el => {
                if(el.id == id){
                    if(el.son && el.son.length){
                        el.son.push({index: `${el.index}-${el.son.length+1}`, level: el.level+1, ...data})
                        let index = this.expandedRowKeys.findIndex(key => key == el.id)
                        this.expandedRowKeys.splice(index, 1)
                        this.expandedRowKeys.push(el.id)
                    }else{
                        el.son = [{
                            index: `${el.index}-1`,
                            level: el.level+1,
                            ...data
                        }]
                        this.expandedRowKeys.push(el.id)
                    }
                }
                if(el.son && el.son.length){
                    this.findItemById(el.son, {id}, data)
                }
            })
        },
        dealArray(data){
            data.forEach((el, index) => {
                this.flatList.push(el)
                if(el.level == 0){
                    el.index = index+1
                }else{
                    el.index += `-${Number(index+1)}`
                }
                if(el.son && el.son.length){
                    this.expandedRowKeys.push(el.id)
                    el.son.forEach((son, sonIndex)=>{
                        if(son)
                        son.index =`${el.index}`
                    })
                    this.dealArray(el.son)
                }else{
                    delete el.son
                }
            })
        },
        editCate(){
            let request = {
                id: this.editId,
                name: this.editType
            }
            edit_exam_bag(request).then(res=>{
                this.visible = false;
                this.findName(this.list, request)

            }).catch(error=>{
                console.log(error)
            })
        },
        findName(data, {id, name}){
            data.forEach(el => {
                if(el.id == id){
                    el.cname = name
                    let index = this.expandedRowKeys.findIndex(key => key == el.parent_id)
                    this.expandedRowKeys.splice(index,1)
                    this.expandedRowKeys.push(el.parent_id)
                }
                if(el.son && el.son.length){
                    this.findName(el.son,{id, name})
                }
            })
        },
        getList(){
            this.loading = true
            this.list = []
            this.expandedRowKeys = []
            this.flatList = []
            get_exam_list({id:this.id}).then(res=>{
                this.loading = false
                if(res.length){
                    res.forEach(el=>{
                        el.show = false
                    })
                    this.list = res
                    this.dealArray(this.list)
                }
            },error=>{
                this.loading = false
                console.log(error)
            })
        },
        changeClass({id}){
            this.id = id
            this.getList()
        },
        toggle(item,index){
            this.list[index].show = !item.show
        },
        deletClass(id, parent_id){
            del_exam_bag({id}).then(res=>{
               this.findDelById(this.list,id, parent_id)
            //    this.getList()
           },error=>{
               console.log(error)
           })
        },
        findDelById(data, id, parent_id){
            data.forEach(el => {
                if(el.id == parent_id){
                    if(el.son.length ==1){
                        delete el.son
                        let index = this.expandedRowKeys.find(key => key == parent_id)
                        this.expandedRowKeys.splice(index, 1)
                    }else{
                        let index = el.son.findIndex(key => key.id == id)
                        el.son.splice(index, 1)
                        el.son.forEach((s, i) => {
                            s.index = `${el.index}-${i+1}`
                        })
                    }
                }
                if(el.son && el.son.length){
                    this.findDelById(el.son, id, parent_id)
                }
            })
        },
        AddClass(item, type){
            this.classType = type
            this.parentClass.visible = true
        },
        AddClass1(item, type){
            console.log(item);
            this.currentId = item.id
            this.currentparent = this.flatList.filter(el => el.id == item.id)[0].cname
            this.openClasses = true;
            this.currentItem = item;
            this.editClass = false
            /*this.classType = type
            this.parentClass.visible1 = true*/
        },
    }
}
</script>
<style lang="less" scoped>
.iconGroup{
    >*{
        padding: 0 12px;
        display: inline-block;
        vertical-align: middle;
    }
    .headImg{
        width: 36px;
        height: 36px;
        border-radius: 100%;
        background: #f07e66;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            display: block
        }
        &.noImg{
            background: #fff
        }
    }
    .processNum{
        color: #4165e0;
        width: 75px;
        font-weight: bold
    }
}
.iconGroup  .anticon{
    font-size:24px;
    color: #4165e0;
    cursor: pointer;
}
.btn{
    width: 105px;
}
.examList{
    margin-bottom:0;
    &.active{
        background: #5180e0;
        color: #fff;
        .num{
            color: #fff
        }
    }
}
.fL .btn{
    background: none;
    color: #5180e0;
    border: 1px solid #5180e0;
    &.active{
        color: #fff;
        background: #5180e0
    }
}
.sonList{
    margin: 0 20px;
    background: #fff;
    border: 1px dashed #5180e0;
    border-top: 0;
    padding: 0 20px;
    overflow: hidden;

}
.slide-fade-enter-active,.slide-fade-leave-active{
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-active {
    height: 30%;
    opacity: 0;
}
.transformIcon{
    transition: transform .3s ease-in-out;
    &.active{
        color: #fff;
        transform: rotateZ(90deg)
    }
}
.iconGroup{
    font-size: 16px
}
.sonItem{
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    padding:0 20px;
    border-bottom: 1px dashed #5180e0;
    &:last-child{
        border-bottom: 0
    }
}
.table{
    /deep/ .ant-table{
        font-size: 16px;
    }
    .num{
        margin-right: 30px;
    }
}
</style>
